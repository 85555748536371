import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Trauma from '../../components/Specialties/components/Trauma';

export default () => (
  <React.Fragment>
    <SEO
      title="Trauma | Mindset Family Therapy"
      description="At Mindset Family Therapy our therapists have been trained in Eye Movement Desensitization and Reprocessing (EMDR), a leading trauma treatment, Trauma Focused-Cognitive Behavior Therapy (TF-CBT), Dialectical Behavior Therapy (DBT) Acceptance & Commitment Therapy (ACT), Internal Family Systems (IFS), Mindfulness-based Therapy, Sandtray Therapy, and Play Therapy. Most importantly, our therapists are client-centered and will utilize the evidenced-based treatment modalities that fit your needs so you can get back to living the life you want to have."
      image="https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498530174/traumaQuoteImage.jpg"
      pathname="/specialties/trauma"
      article
    />
    <Layout>
      <Trauma />
    </Layout>
  </React.Fragment>
);
