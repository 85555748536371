const trauma = {
  quote1: '<p>“When I let go of what I am, I become what I might be.”</p><p>LAO TZU</p>',
  overview:
    'Have you ever been in a situation where you felt terrified, helpless, powerless, or unsafe? A traumatic event that may have left you in shock. If you have not been able to get over it, you may have developed Post-traumatic Stress Disorder (PTSD). Maybe you witnessed the trauma and helped the victim, but you are still having a difficult time dealing with it yourself.',
  signs: [
    'Did you think you were getting over the trauma and then suddenly one day, you see, smell, hear, taste, or touch' +
      ' something that triggers the awful memories? When these memories occur, does your anxiety level go up?',

    'Do you experience nightmares, flashbacks or disturbing memories of the event? ',

    'Do you experience strong heart palpitations, sweaty hands, or your limbs feeling numb?',

    'Do you feel shortness of breath?',

    'Do you have difficulty falling asleep and staying asleep?',

    'Does it seem like you are irritable all the time?',

    'Do you have a hard time concentrating in school or at work because you keep going over the traumatic event in' +
      ' your mind?',

    'Do you feel like you are on “alert-mode” all the time and are easily startled?',

    'Do you avoid situations, events, activities, places, and people that remind you of the traumatic event?',

    'Do you feel depressed, hopeless, numb, or guilty because of what happened?',

    'Do you believe you could’ve prevented the event?',

    'Have you lost interest in activities you used to enjoy?',
  ],
  overview2:
    'If you are experiencing any of these symptoms, and have tried to get over them on your own but can’t,' +
    ' it may be time to seek out professional help. Have you seen your medical provider to determine if you need' +
    ' medication? Have you found a therapist that is experienced in treating Post-Traumatic Stress Disorder?<br><br>At Mindset Family Therapy our therapists have been trained in Trauma Focused-Cognitive Behavior Therapy (TF-CBT), Dialectical Behavior Therapy (DBT) Acceptance & Commitment Therapy (ACT), Internal Family Systems (IFS), Mindfulness-based Therapy, Sandtray Therapy, and Play Therapy. Most importantly, our therapists are client-centered and will utilize the evidenced-based treatment modalities that fit your needs so you can get back to living the life you want to have.',
  traumaAndChildren:
    'When children sustain trauma, they are overwhelmed, upset, and confused. They may experience guilt and shame.' +
    ' They experience intense emotions and may not be able to express them. They also experience nightmares and' +
    ' inability to stay focused during the day. They may become hypervigilant, get easily startled, and withdraw' +
    ' from family and friends. Their parents also suffer. Intense emotions such as regret, guilt, and shame may be' +
    ' present. They may endure sleepless nights and high anxiety as well. Sometimes the whole family needs therapy' +
    ' to be able to sort out the trauma.<br/><br/>If your child is younger than twelve-years old, we utilize play' +
    ' therapy as well as other treatment modalities mentioned above. We will adjust the level of instruction and' +
    ' therapeutic skills according to his or her cognitive level.<br><br>We would feel honored to help you in your' +
    ' journey of growth and hope, and be part of your healing process.<br><br>Please reach out to us to schedule an appointment with one of our caring professional therapists to get you and your child back to enjoying life more fully!',
};

export default trauma;
