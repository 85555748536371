import _ from 'lodash';
import React from 'react';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import data from '../../../../data/trauma';

import styles from './trauma.module.scss';

const Trauma = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498529945/otherSpecialties.jpg';
  const image1 =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498530174/traumaQuoteImage.jpg';

  const signs = _.map(data.signs, (sign, index) => {
    return <li key={index}>{sign}</li>;
  });

  return (
    <main>
      <PageHeader pageCategory="Specialties" pageName="Trauma" headerImage={background} />

      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.overview }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <ul>{signs}</ul>
      </div>

      <QuoteImage image={image1} maxWidth={300} quote={data.quote1} />

      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.overview2 }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>Trauma and Children</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.traumaAndChildren }} />
      </div>
    </main>
  );
};

export default Trauma;
